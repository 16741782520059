#product-box {
    text-align: center;
}
#product-box img {
    max-width: 280px;
}
#product-box h3 {
    font-family: 'Domine', serif;
    color: #1a5632;
    font-size: 18px;
    text-transform: uppercase;
}
#product-box p {
   color: #4b402a;
   text-transform: uppercase;
   font-weight: 300;
   font-size: 18px;
   position: relative;
   bottom: 10px;
}
#no-products-text {
    margin: 0 15px
}

@media(min-width: 768px){
    #products-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        bottom: 60px
    }
    #product-box {
        width: 300px;
        margin: 0 auto
    }
    #product-box img {
        max-height: 200px
    }
}