#hero {
    background: linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.4)), url('../../images/hero-background.jpg');
    height: 520px;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 80px 15px 0;
    position: relative
}
#hero img {
    width: 150px
}
#hero h1 {
    font-size: 48px;
    line-height: 100%;
    font-family: 'Domine', serif;
}
#hero p {
    font-size: 18px;
    position: relative;
    bottom: 15px;
    letter-spacing: 0.2px;
}