body {
  margin: 0;
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  overflow-x: hidden;
  overscroll-behavior: none;
}

input, textarea, button {
  outline:none;
 }

 a {
  text-decoration: none;
}

