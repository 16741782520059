#form-product-page input {
    background-color: #fafafa;
    border: 1px solid #4b402a;
    padding: 0 5% 0 10px;
    height: 35px;
    width: 50%
}
#form-product-page input::placeholder {
    color: #4b402a;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
    font-family: 'Oswald', sans-serif;
}
#form-product-page button {
    background-color: #1a5632;
    color: #fff;
    border: none;
    height: 37px;
    position: relative;
    top: 1px;
    width: 40%;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 0.1px;
}
#form-product-page button:hover {
    cursor: pointer;
}