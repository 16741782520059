#subfooter {
    background: #439f35;
    padding: 20px 15px 40px;
}

#copyright {
    color: #fff
}

#subfooter a {
    color: #fafafa
}
#subfooter div:first-child {
    margin-bottom: 60px
}

@media(min-width: 968px){
    #subfooter {
        display: flex;
        justify-content: space-evenly;
        padding: 10px 0 15px;
        font-size: 14px;
    }
    #subfooter p {
        margin: 10px
    }

    #subfooter div:first-child {
       display: flex;
       margin-bottom: 0;
    }
    #subfooter div:nth-child(2) {
       position: relative;
       top: 10px
    }
}