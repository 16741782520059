footer {
    background-color: #1a5632;
    padding: 40px 15px 60px;
}
footer h4 {
    color: #ffdf70
}

footer p, footer a {
    color: #fff;
    line-height: 80%;
}

footer div {
    margin-bottom: 40px
}

footer #icons-container {
    display: flex;
    justify-content: center;
}

#logo-footer {
    display: block;
    margin: 0 auto;
}

footer #icons-container img {
    padding: 20px 10px 0
}

@media(min-width: 968px){
    footer {
        display: flex;
        justify-content: space-evenly;
    }
}