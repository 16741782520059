#box-form-page {
    background-color: #fff1c4;
    position: relative;
    bottom: 80px;
    z-index: 99;
    margin: 0 15px; 
    padding: 10px 20px 40px
}
#box-form-page h3 {
    color: #4b402a;
    margin-bottom: 40px
}
#box-form-page #images-container {
   margin-top: 40px
}
#box-form-page #images-container img {
   display: inline-block;
   margin: 0 5px;
   width: 80px
}
#pasta-package-container {
    display: none;
}

@media(min-width:968px){
   #box-form-page {
        display: flex;
        width: 60%;
        margin: 0 auto;
        bottom: 160px;
        padding: 20px 0px 30px;
        justify-content: space-evenly;
    }
    #pasta-package-container {
        display: block;
    }
    #box-form-page div:first-child {
        width: 60%;
    }
    #box-form-page {
        display: flex;
    }
    #box-form-page #images-container img {
        width: 100px;
        margin-right: 10px
     }
     #pasta-package-container img {
        width: 360px;
    }
}