#hero-last-page {
    background: linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.4)), url('../../../images/hero-last-page.jpg');
    height: 520px;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 80px 15px 0;
    position: relative
}

#hero-last-page #product-image {
    position: relative;
    margin: 0 auto;
    top: 20px
}

#product-image-container img {
    width: 300px;
    height: auto;
    position: relative;
    top: 20px;
    display: block;
    margin: 0 auto;
}
@media(min-width: 768px){
    #product-image-container img {
        width: 400px;
        top: 20px;
    }
}