#logo-container {
    text-align: center;
    margin-top: 10px;
}
#logo-container {
    display: flex;
    justify-content: space-between;
    padding: 8px 15px
}
header nav ul li  {
    list-style: none;
}
header nav ul li a {
    text-decoration: none;
    color: #144D22;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
}
#shop img {
    display: none
}
#burger-icon {
    background-color: #ffdf70;
    width: 30px;
    padding: 8px 5px;
    text-align: center;
    height: 22px;
    position: relative;
    top: 50px
}
#burger-icon .line {
    height: 2px;
    width: 15px;
    background-color: #144d22;
    margin: 4px auto
}
#x {
    font-size: 23px;
    font-weight: 300;
    position: relative;
    bottom: 8px
}

header nav ul {
    margin: 0;
    padding: 0
}
header nav ul li  {
    border-top: 1px solid #f7f7f7;
    padding: 15px 20px
} 

@media(min-width: 968px){
    header nav ul {
        display: flex;
        justify-content: center;
    }
    header nav ul li {
        margin: 10px 15px 15px;
        border: none;
        padding: 0
    }
    header nav ul li a:hover {
        border-bottom: 1px solid #144D22
    }
    #shop {
        background-color: #ffdf70;
        padding: 5px 15px;
        display: flex;
        position: relative;
        bottom: 4px;
        border: 1px solid #ffdf70
    }
    #shop:hover {
       border: 1px solid green
    }
    #shop a {
        color: #4b402a
    }
    #shop a:hover {
        border: none;
    }
    #shop img {
        width: 18px;
        height: 18px;
        position: relative;
        top: 2px;
        right: 2px
    }
    #burger-icon {
        display: none;
    }
    #logo-container {
        justify-content: center;
    }
    #shop img {
        display: block
    }
}