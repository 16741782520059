.box img {
    max-width: 100%;
    height: auto;
}

@media(min-width: 968px){
    .box {
        display: flex;
    }
    .box div {
        width: 50%;
    }
    .box img {
        position: relative;
        top: 60px;
    }
    /* first and third row  */
    .row-reverse {
        flex-direction: row-reverse;
    }
     /* second and fourth row */ 
    .box img:nth-child(odd) {
        left: 120px;
    }
}