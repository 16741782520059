#box-home{
    background-color: #fff1c4;
    position: relative;
    bottom: 80px;
    z-index: 99;
    margin: 0 15px; 
    padding: 10px 20px 60px
}
#box-home h2 {
    color: #1a5632;
    font-size: 22px;
    line-height: 130%;
    margin-bottom: 20px
}
#box-home-subtitle {
    font-size: 16px;
    color: #4b402a;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 40px;
}

#search-box-img-container img  {
    margin: 20px auto 0px;
    display: inline-block;
    width: 100%;
    height: auto;
    max-width: 537px;
}

@media(min-width: 968px){
    #box-home {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 70%;
        margin: 0 auto;
        bottom: 160px;
        padding: 10px 20px 40px;
    }
    #box-home div {
        width: 45%;
        margin: 0 auto;
    }
    #box-home #search-input {
        position: relative;
        bottom: 5px;
    }
}

/* To hide the image */
@media(min-width: 965px) and (max-width: 1200px){
    #box-home {
        text-align: left;
        padding: 10px 40px 40px;
    }
    #box-home div {
        width: 100%
    }
    #search-box-img-container  {
        display: none;
    }
}

/* Big screens */
@media(min-width: 1600px){
    #box-home {
        text-align: left;
        padding: 10px 40px 40px;
    }
    #box-home div:nth-child(2) {
        position: relative;
        top: 40px
    }
    #box-home div {
        width: 40%;
        margin: 0 auto;
    }
  
}