#video-last-page {
    margin: 80px 0 80px
}
#video-last-page h2 {
    color: #1a5632;
    font-size: 26px;
    font-family: 'Domine', serif;
    text-align: center;
}
 #video-container-desktop {
   display: none
} 
@media(min-width: 768px){
    #video-last-page {
        margin: 80px 0 80px
    }
    #video-container-mobile {
        display: none;
    }
    #video-container-desktop {
        display: block;
    }
}