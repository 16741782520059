#search-input {
    background-color: #f6f6f6;
    width: 90%;
    border: 1px solid #4b402a;
    padding: 0 5%;
    height: 35px;
}

#search-input::placeholder {
    color: #4b402a;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: 'Oswald', sans-serif;
}